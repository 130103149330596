// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  privacyPolicies: '/privacy-policies',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    file: path(ROOTS_DASHBOARD, '/file'),
  
    // checkdrive: path(ROOTS_DASHBOARD, '/file'),
    
  },

  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    account: path(ROOTS_DASHBOARD, '/company/account'),
  },

  checkdrive: {
    root: path(ROOTS_DASHBOARD, '/checkdrive'),
    file: path(ROOTS_DASHBOARD, '/checkdrive/file'),

  },
  client: {
    root: path(ROOTS_DASHBOARD, '/client'),
    list: path(ROOTS_DASHBOARD, '/client/list'),
    new: path(ROOTS_DASHBOARD, '/client/new'),
    update: path(ROOTS_DASHBOARD, '/client/update/:id'), // Ruta de actualización
    norm: path(ROOTS_DASHBOARD, '/client/norm/:id'), // Ruta de configuración
},

measurement: {
  root: path(ROOTS_DASHBOARD, '/measurement'),
  list: path(ROOTS_DASHBOARD, '/measurement/list'),
  new: path(ROOTS_DASHBOARD, '/measurement/new'),
  update: path(ROOTS_DASHBOARD, '/measurement/update/:id'), // Ruta de actualización
},

  species: {
    root: path(ROOTS_DASHBOARD, '/species'),
    list: path(ROOTS_DASHBOARD, '/species/list'),
    new: path(ROOTS_DASHBOARD, '/species/new'),
    update: path(ROOTS_DASHBOARD, '/species/update/:id'), // Ruta de actualización
    variety: path(ROOTS_DASHBOARD, '/variety/list'),                                    // Ruta de configuración
  },

  variety: {
    root: path(ROOTS_DASHBOARD, '/variety'),
    list: path(ROOTS_DASHBOARD, '/variety/list'),
    new: path(ROOTS_DASHBOARD, '/variety/new'),
    update: path(ROOTS_DASHBOARD, '/variety/update/:id'), // Ruta de actualización
    setting: path(ROOTS_DASHBOARD, '/variety/setting/:id'), // Ruta de configuración
  },

  grower: {
    root: path(ROOTS_DASHBOARD, '/grower'),
    list: path(ROOTS_DASHBOARD, '/grower/list'),
    new: path(ROOTS_DASHBOARD, '/grower/new'),
    update: path(ROOTS_DASHBOARD, '/grower/update/:id'),
    setting: path(ROOTS_DASHBOARD, '/grower/setting/:id'),

  },

  exporter: {
    root: path(ROOTS_DASHBOARD, '/exporter'),
    list: path(ROOTS_DASHBOARD, '/exporter/list'),
    new: path(ROOTS_DASHBOARD, '/exporter/new'),
    update: path(ROOTS_DASHBOARD, '/exporter/update/:id'),

  },

  packing: {
    root: path(ROOTS_DASHBOARD, '/packing'),
    list: path(ROOTS_DASHBOARD, '/packing/list'),
    new: path(ROOTS_DASHBOARD, '/packing/new'),
    update: path(ROOTS_DASHBOARD, '/packing/update/:id'),
    setting: path(ROOTS_DASHBOARD, '/packing/setting/:id'),
    size: path(ROOTS_DASHBOARD, '/packing/size/:id'),
    intake: path(ROOTS_DASHBOARD, '/packing/intake/:id'),
  },


};


