import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DataStore, Storage } from 'aws-amplify';
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import { Company } from '../../../models';
import { useAuthContext } from '../../../auth/useAuthContext';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { CustomAvatar } from '../../../components/custom-avatar';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function NavAccount() {
  const { companyId } = useAuthContext();
  const [companyData, setCompanyData] = useState({});

  useEffect(() => {
    const fetchCompanyData = async () => {
      const company = await DataStore.query(Company, companyId);
      try {
        const logoUrl = await Storage.get(company.photo);
        setCompanyData({ ...company, photoURL: logoUrl });
      } catch (error) {
        console.log('Error fetching logo:', error);
        setCompanyData(company);
      }
    };

    if (companyId) {
      fetchCompanyData();

      const subscription = DataStore.observe(Company, companyId).subscribe(msg => {
        if (msg.opType === 'UPDATE') {
          fetchCompanyData();
        }
      });

      return () => subscription.unsubscribe();
    }

    return () => {}; // Return empty cleanup function if `companyId` is not truthy
  }, [companyId]);

  return (
    <Link component={RouterLink} to={PATH_DASHBOARD.company.account} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar src={companyData?.photoURL} alt={companyData?.name} name={companyData?.name} />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {companyData?.name}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {companyData?.id}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
