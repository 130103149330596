// import CloudDoneIcon from '@mui/icons-material/CloudDone';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

import SvgColor from '../../../components/svg-color';


// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  checkdrive: icon('checkdrive'),
  norm: icon('norm'),
  species: icon('ic_apple'),
  messurament: icon('ic_mediciones'),
  grower: icon('ic_grower'),
  packing: icon('ic_pack'),
  exporter: icon('ic_exporter'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Reportes', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Compañía', path: PATH_DASHBOARD.company.account, icon: ICONS.banking },
    //  { title: 'Check Drive', path: PATH_DASHBOARD.general.file, icon: ICONS.checkdrive },
      { title: 'Clientes', path: PATH_DASHBOARD.client.list, icon: ICONS.user },
      {
        title: 'Especies',
        path: PATH_DASHBOARD.species.list,
        icon: ICONS.species,
        children: [
          { title: 'Especies', path: PATH_DASHBOARD.species.list },
          { title: 'Variedades', path: PATH_DASHBOARD.variety.list }
        ],
      },
      { title: 'Productores', path: PATH_DASHBOARD.grower.list, icon: ICONS.grower },
      { title: 'Exportadores', path: PATH_DASHBOARD.exporter.list, icon: ICONS.exporter },
      { title: 'Sitios de embalaje', path: PATH_DASHBOARD.packing.list, icon: ICONS.packing },
    ],
  },
];

export default navConfig;
