// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const DefectTypes = {
  "PROGRESSIVE": "PROGRESSIVE",
  "NONPROGRESSIVE": "NONPROGRESSIVE"
};

const { Company, Client, Norm, User, IntakeReport, Comment, IntakeSample, SampleComment, FinishReport, CommentFinish, FinishSample, SampleCommentFinish, VisitReport, CommentVisit, VisitSample, SampleCommentVisit, DestinationReport, CommentDestination, DestinationSample, SampleCommentDestination, Specie, Variety, SizeVariety, ColorVariety, DefectVariety, AttributeVariety, Packing, PackFormat, IntakeFormat, SizeFormat, Grower, Field, Exporter } = initSchema(schema);

export {
  Company,
  Client,
  Norm,
  User,
  IntakeReport,
  Comment,
  IntakeSample,
  SampleComment,
  FinishReport,
  CommentFinish,
  FinishSample,
  SampleCommentFinish,
  VisitReport,
  CommentVisit,
  VisitSample,
  SampleCommentVisit,
  DestinationReport,
  CommentDestination,
  DestinationSample,
  SampleCommentDestination,
  Specie,
  Variety,
  SizeVariety,
  ColorVariety,
  DefectVariety,
  AttributeVariety,
  Packing,
  PackFormat,
  IntakeFormat,
  SizeFormat,
  Grower,
  Field,
  Exporter,
  DefectTypes
};