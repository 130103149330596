import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);
// ----------------------------------------------------------------------
// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
// export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
// export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
// export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
// export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));
export const GeneralFilePage = Loadable(lazy(() => import('../pages/dashboard/GeneralFilePage')));
// DASHBOARD: CLIENT
export const ClientListPage = Loadable(lazy(() => import('../pages/dashboard/ClientListPage')));
export const ClientCreatePage = Loadable(lazy(() => import('../pages/dashboard/ClientCreatePage')));
export const ClientUpdatePage = Loadable(lazy(() => import('../pages/dashboard/ClientUpdatePage')));
export const ClientSetNormPage = Loadable(lazy(() => import('../pages/dashboard/ClientSetNormPage')));
// DASHBOARD: MEASURE
// export const MeasurementListPage = Loadable( lazy(() => import('../pages/dashboard/MeasurementListPage')));
// DASHBOARD: SPECIES
export const SpeciesListPage = Loadable(lazy(() => import('../pages/dashboard/SpeciesListPage')));
export const SpeciesCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/SpeciesCreatePage'))
);
export const SpeciesUpdatePage = Loadable(
  lazy(() => import('../pages/dashboard/SpeciesUpdatePage'))
);
// DASHBOARD: VARIETY
export const VarietyListPage = Loadable(lazy(() => import('../pages/dashboard/VarietyListPage')));
export const VarietyCreatePage = Loadable(lazy(() => import('../pages/dashboard/VarietyCreatePage')));
export const VarietyUpdatePage = Loadable(lazy(() => import('../pages/dashboard/VarietyUpdatePage')));
export const SetVarietyUpdatePage = Loadable(lazy(() => import('../pages/dashboard/SetVarietyUpdatePage')));

// DASHBOARD: USER
export const UserUpdatePage = Loadable(lazy(() => import('../pages/dashboard/UserUpdatePage')));

// DASHBOARD: GROWER
export const GrowerListPage = Loadable(lazy(() => import('../pages/dashboard/GrowerListPage')));
export const GrowerCreatePage = Loadable(lazy(() => import('../pages/dashboard/GrowerCreatePage')));
export const GrowerUpdatePage = Loadable(lazy(() => import('../pages/dashboard/GrowerUpdatePage')));
export const GrowerUpdateFieldPage = Loadable(  lazy(() => import('../pages/dashboard/GrowerUpdateFieldPage')));
// DASHBOARD: EXPORTER
export const ExporterListPage = Loadable(lazy(() => import('../pages/dashboard/ExporterListPage')));
export const ExporterCreatePage = Loadable(lazy(() => import('../pages/dashboard/ExporterCreatePage')));
export const ExporterUpdatePage = Loadable(lazy(() => import('../pages/dashboard/ExporterUpdatePage')));


// DASHBOARD: PACKING
export const PackingListPage = Loadable(lazy(() => import('../pages/dashboard/PackingListPage')));
export const PackingCreatePage = Loadable(lazy(() => import('../pages/dashboard/PackingCreatePage')));
export const PackingUpdatePage = Loadable(lazy(() => import('../pages/dashboard/PackingUpdatePage')));
export const PackingUpdateFormatPage = Loadable(  lazy(() => import('../pages/dashboard/PackingUpdateFormatPage')));
export const PackingUpdateSizePage = Loadable(  lazy(() => import('../pages/dashboard/PackingUpdateSizePage')));
export const PackingUpdateIntakeFormatPage = Loadable(  lazy(() => import('../pages/dashboard/PackingUpdateIntakeFormatPage')));


// DASHBOARD: COMPANY
export const CompanyAccountPage = Loadable(
  lazy(() => import('../pages/dashboard/CompanyAccountPage'))
);
// DASHBOARD: FILE MANAGER
export const FileManagerPage = Loadable(lazy(() => import('../pages/dashboard/FileManagerPage')));
// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);
// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));
// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const PrivacyPolicies = Loadable(lazy(() => import('../pages/PrivacyPolicies')));
