// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  demo: {
    title: `Chinese`,
    introduction: `Lorem Ipsum 只是印刷和排版行业的虚拟文本。 自 1500 年代以来，Lorem Ipsum 一直是行业的标准虚拟文本，当时一位不知名的印刷商采用了一种类型的厨房并将其加扰以制作类型样本书。 它不仅经历了五个世纪，而且经历了电子排版的飞跃，基本保持不变。 它在 1960 年代随着包含 Lorem Ipsum 段落的 Letraset 表的发布而流行起来，最近还随着 Aldus PageMaker 等桌面出版软件（包括 Lorem Ipsum 的版本）而普及.`,
  },
  docs: {
    hi: `你好`,
    description: `需要帮忙？\n 请查看我们的文档.`,
    documentation: `文件`,
  },

  Desempeño: '绩效',
  Cuenta: '账户',
  Todos: '所有',
  Clientes: '客户',
  Reportes: '报告',
  Compañía: '公司',
  Exportadores: '出口商',
  Productores: '生产商',
  Especies: '物种',
  Normas: '规则',
  Todas: '所有',
  'Nueva Norma': '新规则',
  Variedades: '品种',
  Recepción: '接收',
  'Producto Terminado': '成品',
  Destino: '目的地',
  Visita: '访问',
  all: '所有',
  'En curso': '进行中',
  Terminado: '已完成',
  Enviado: '已发送',
  Cliente: '客户',
  'N° Referencia': '参考编号',
  'N° Contenedor': '集装箱号',
  Especie: '物种',
  'Kg entreda': '公斤输入',
  'N° Muestras': '样品数',
  Status: '状态',
  'Fecha de creación': '创建日期',
  Reporte: '状态',
  'Información General': '一般信息',
  'Configuraciones de la cuenta': '账户设置',
  'Nombre de la empresa': '公司名称',
  'Correo electrónico': '电子邮件',
  Usuarios: '用户',
  'Guardar cambios': '保存更改',
  'Nombre del cliente': '客户名称',
  Estado: '状态',
  'Nuevo cliente': '新客户',
  'Todos los clientes': '所有客户',
  Activo: '活跃',
  Inactivo: '不活跃',
  Activos: '活跃',
  Inactivos: '不活跃',
  Nombre: '名称',
  'Todas las especies': '所有物种',
  'Nueva especie': '新物种',
  'Nueva variedad': '新品种',
  'Cliente asignado': '分配的客户',
  'Nombre especie': '物种名称',
  'Nombre variedad': '品种名称',
  'Todas las variedades': '所有品种',
  'Nombre Productor': '生产商名称',
  'Todos los productores': '所有生产商',
  'Nueva productor': '新生产商',
  'Nombre Exportador': '出口商名称',
  'Todos los exportadores': '所有出口商',
  'Nuevo exportador': '新出口商',
  'Sitios de embalaje': '包装站点',
  'Nombre Packing': '包装名称',
  'Todos los Sitios de embalaje': '所有包装站点',
  'Nuevo sitio de embalaje': '新包装站点',
  'Crear nuevo cliente': '创建新客户',
  'Nuevo Cliente': '新客户',
  'Activar cliente': '激活客户',
  'Idioma de reporte': '报告语言',
  '¡Cliente actualizado con éxito!': '客户成功更新！',
  'Error al enviar datos:': '发送数据错误：',
  'Editar cliente': '编辑客户',
  'Crear nueva especie': '创建新物种',
  'Nueva Especie': '新物种',
  'Activar especie': '激活物种',
  'Nombre de la especie': '物种名称',
  'Editar especie': '编辑物种',
  'Editar Especie': '编辑物种',
  'Actualizar especie': '更新物种',
  'Crear nueva Variedad': '创建新品种',
  'Nueva Variedad': '新品种',
  'Activar variedad': '激活品种',
  Variedad: '品种',
  'Crear variedad': '创建品种',
  'Nombre de la variedad': '品种名称',
  'Especie/Cliente': '物种/客户',
  'Editar Variedad': '编辑品种',
  'Actualizar variedad': '更新品种',
  'Nuevo productor': '新生产商',
  'Crear nuevo productor': '创建新生产商',
  'Crear nuevo Productor': '创建新生产商',
  'Nombre del Productor': '生产商名称',
  'Editar productor': '编辑生产商',
  'Actualizar productor': '更新生产商',
  'Nombre del productor': '生产商名称',
  'Activar Productor': '激活生产商',
  'Crear nuevo Exportador': '创建新出口商',
  'Nuevo Exportador': '新出口商',
  'Activar Exportador': '激活出口商',
  'Nombre del Exportador': '出口商名称',
  'Crear nuevo exportador': '创建新出口商',
  'Editar exportador': '编辑出口商',
  'Actualizar exportador': '更新出口商',
  'Nombre del exportador': '出口商名称',
  'Editar Exportador': '编辑出口商',
  'Activar exportador': '激活出口商',
  'Nuevo Packing': '新包装',
  'Activar sitio de embalaje': '激活包装站点',
  'Nombre del Packing': '包装名称',
  'Crear nuevo sitio de embalaje': '创建新包装站点',
  'Editar sitio de embalaje': '编辑包装站点',
  'Actualizar sitio de embalaje': '更新包装站点',
  'Nombre del sitio de embalaje': '包装站点名称',
  Detalles: '详细信息',
  Descargar: '下载',
  Borrar: '删除',
  Editar: '编辑',
  '¿Estás seguro de que quieres eliminar al cliente?': '您确定要删除此客户吗？',
  '¿Estás seguro de que quieres eliminar esta especie?': '您确定要删除这个物种吗？',
  Configurar: '设置',
  'Nuevo campo': '新字段',
  'Formatos Empaques': '包装格式',
  Tamaños: '水果尺寸',
  'Formatos de empaque': '包装格式',
  'Crear formato de empaque': '创建包装格式',
  'Formato de empaque': '包装格式',
  'Modificar formato de empaque': '修改包装格式',
  Cancelar: '取消',
  Guardar: '保存',
  Crear: '创建',
  'Crear tamaño': '创建尺寸',
  'Crear Tamaño': '创建尺寸',
  'Configurar Variedad': '设置品种',
  Defectos: '缺陷',
  Lecturas: '读数',
  Colores: '颜色',
  tamaños: '尺寸',
  'Tipo de defecto': '缺陷类型',
  'Valor Precución': '预防值',
  'Valor Peligro': '危险值',
  'Crear Defecto': '创建缺陷',
  'Crear defecto': '创建缺陷',
  'Defecto Crítico': '关键缺陷',
  'Valor de Peligro': '危险值',
  'Valor de Precaución': '预防值',
  'Nuevo defecto': '新缺陷',
  Tipo: '类型',
  'Editar defecto': '编辑缺陷',
  'Unidad de medida': '测量单位',
  'Valor minimo ideal': '理想最小值',
  'Valor máximo ideal': '理想最大值',
  'Crear lectura': '创建读数',
  'Editar lectura': '编辑读数',
  'Valor minimo permitido': '允许的最小值',
  'Valor maximo permitido': '允许的最大值',
  'Lectura criticas': '关键读数',
  'Lectura critica': '关键读数',
  Actualizar: '更新',
  'Crear color': '创建颜色',
  'Editar tamaño': '编辑尺寸',
  'Editar color': '编辑颜色',
  'Nombre del color': '颜色名称',
  'Nuevo Campo': '新字段',
  'Modificar Campo': '修改字段',
  'Crear Campo': '创建字段',
  Campos: '字段',
  '¡Cliente y norma actualizado con éxito!': '客户和规则更新成功！',
  'Error actualizando la norma': '更新规则时出错',
  'Guardar normas': '保存规则',
  'Formato de admisión': '接收格式',
  'Nuevo formato de admisión': '新接收格式',
  'Modificar formato de admisión': '修改接收格式',

  Total: `总`,
  app: `应用程序`,
  user: `用户`,
  list: `列表`,
  edit: `编辑`,
  shop: `商店`,
  blog: `博客`,
  post: `邮政`,
  mail: `电子邮件`,
  chat: `聊天`,
  cards: `牌`,
  posts: `帖子`,
  create: `创建`,
  kanban: `登机`,
  general: `一般的`,
  banking: `银行业`,
  booking: `预订`,
  profile: `轮廓`,
  account: `帐户`,
  product: `产品`,
  invoice: `发票`,
  details: `细节`,
  checkout: `退房`,
  calendar: `日历`,
  analytics: `分析`,
  ecommerce: `电子商务`,
  management: `管理`,
  menu_level: `菜单级别`,
  menu_level_2a: `菜单级别 2a`,
  menu_level_2b: `菜单级别 2b`,
  menu_level_3a: `菜单级别 3a`,
  menu_level_3b: `菜单级别 3b`,
  menu_level_4a: `菜单级别 4a`,
  menu_level_4b: `菜单级别 4b`,
  item_disabled: `项目已禁用`,
  item_label: `项目标签`,
  item_caption: `项目标题`,
  item_external_link: `项目外部链接`,
  description: `描述`,
  other_cases: `其他情况`,
  item_by_roles: `按角色逐项`,
  only_admin_can_see_this_item: `只有管理员可以看到这个项目`,
};

export default cn;
