import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { Auth, DataStore } from 'aws-amplify';
import { User, Company } from '../models';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  userId: null,
  companyId: null,
  userName: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      return {
        ...state,
        isAuthenticated: Boolean(action.payload.userId),
        isInitialized: true,
        userId: action.payload.userId,
        companyId: action.payload.companyId,
        userName: action.payload.userName,
      };
    }
    case 'SIGN_IN': {
      return {
        ...state,
        isAuthenticated: true,
        userId: action.payload.userId,
        companyId: action.payload.companyId,
        userName: action.payload.userName,
      };
    }
    case 'SIGN_OUT': {
      return {
        ...state,
        isAuthenticated: false,
        userId: null,
        companyId: null,
        userName: null,
      };
    }
    default: {
      return state;
    }
  }
};

export const AuthContext = createContext();

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [authState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        const userData = await DataStore.query(User, (u) => u.sub.eq(user.attributes.sub));
        const companyData = await DataStore.query(Company, (c) =>
          c.id.eq(user.attributes['custom:companyID'])
        );
        dispatch({
          type: 'INIT',
          payload: { userId: userData[0].id, companyId: companyData[0].id, userName: userData[0].name },
        });
      })
      .catch(() => dispatch({ type: 'INIT', payload: { userId: null, companyId: null, userName: null } }));
  }, []);

  const login = useCallback(async (username, password) => {
    try {
      const user = await Auth.signIn(username, password);
  
      // Comprobar si el usuario pertenece al grupo Admin
      const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
      if (!groups.includes('Admin')) {
        alert("No tienes los permisos necesarios, comunícate con el administrador.");
        await Auth.signOut();
        return;
      }
  
      await DataStore.start();
      // Fetch user and company data
      const userData = await DataStore.query(User, (u) => u.sub.eq(user.attributes.sub));
      const companyData = await DataStore.query(Company, (c) => c.id.eq(user.attributes['custom:companyID']));
  
      // Check if results exist before accessing properties
      const userId = userData && userData[0] ? userData[0].id : null;
      const companyId = companyData && companyData[0] ? companyData[0].id : null;
      const userName = userData && userData[0] ? userData[0].name : null;
  
      // Log userId, companyId, and userName to console
      console.log('userId:', userId);
      console.log('companyId:', companyId);
      console.log('userName:', userName);
  
      // If userId, companyId, and userName are not null, then dispatch SIGN_IN
      if (userId && companyId && userName) {
        dispatch({ type: 'SIGN_IN', payload: { userId, companyId, userName } });
      } else {
        // If either userId, companyId, or userName is null, throw an error
        throw new Error('Unable to fetch user and/or company data');
      }
    } catch (error) {
      console.log('error signing in', error);
      // En un entorno de producción, considera utilizar un enfoque más genérico para manejar errores
    }
  }, []);
  

  const register = useCallback(async (username, password) => {
    try {
      await Auth.signUp({ username, password });
      window.location.href = '/login'; // replace with your login route
    } catch (error) {
      console.log('error signing up', error);
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await Auth.signOut();
      dispatch({ type: 'SIGN_OUT' });
    } catch (error) {
      console.log('error signing out', error);
    }
  }, []);

  const value = useMemo(
    () => ({
      ...authState,
      method: 'amplify',
      login,
      register,
      logout,
    }),
    [authState, login, register, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}