import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  LoginPage,
  GeneralAppPage,
  GeneralFilePage,
  ClientListPage,
  ClientCreatePage,
  ClientUpdatePage,
  ClientSetNormPage,
  // Dashboard: Species
  SpeciesListPage,
  SpeciesCreatePage,
  SpeciesUpdatePage,
  // Dashboard: Variety
  VarietyListPage,
  VarietyCreatePage,
  VarietyUpdatePage,
  SetVarietyUpdatePage,
  // Dashboard: Grower
  GrowerListPage,
  GrowerCreatePage,
  GrowerUpdatePage,
  GrowerUpdateFieldPage,
  // Dashboard: Exporter
  ExporterListPage,
  ExporterCreatePage,
  ExporterUpdatePage,


  // Dashboard: Packing
  PackingListPage,
  PackingCreatePage,
  PackingUpdatePage,
  PackingUpdateFormatPage,
  PackingUpdateIntakeFormatPage,
  PackingUpdateSizePage,
  // Dashboard: Company
  CompanyAccountPage,
  // BlankPage,
  // PermissionDeniedPage,
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  PrivacyPolicies,
  
  // User
  UserUpdatePage,
  
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <GeneralAppPage />, index: true },
      ],
    },

    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'file', element: <GeneralFilePage /> },
        { path: 'client',
          children: [
            { element: <Navigate to="/dashboard/client" replace />, index: true },
            { path: 'list', element: <ClientListPage /> },
            { path: 'new', element: <ClientCreatePage /> },
            { path: 'update/:id', element: <ClientUpdatePage /> },
            { path: 'norm/:id', element: <ClientSetNormPage />}
          ],
        },
        { path: 'company',
          children: [
            { element: <Navigate to="/dashboard/company" replace />, index: true },
            { path: 'account', element: <CompanyAccountPage /> },
          ],
        },

        { path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user" replace />, index: true },
            { path: 'update/:id', element: <UserUpdatePage /> },
          ],
        },

        { path: 'species',
          children: [
            { element: <Navigate to="/dashboard/species" replace />, index: true },
            { path: 'list', element: <SpeciesListPage /> },
            { path: 'new', element: <SpeciesCreatePage /> },
            { path: 'update/:id', element: <SpeciesUpdatePage /> },
            { path: 'variety', element: <VarietyListPage />}
          ],
        },
        { path: 'variety',
          children: [
            { element: <Navigate to="/dashboard/variety" replace />, index: true },
            { path: 'list', element: <VarietyListPage /> },
            { path: 'new', element: <VarietyCreatePage /> },
            { path: 'update/:id', element: <VarietyUpdatePage /> },
            { path: 'setting/:id', element: <SetVarietyUpdatePage /> },
          ],
        },
        { path: 'grower',
          children: [
            { element: <Navigate to="/dashboard/grower" replace />, index: true },
            { path: 'list', element: <GrowerListPage /> },
            { path: 'new', element: <GrowerCreatePage /> },
            { path: 'update/:id', element: <GrowerUpdatePage /> },
            { path: 'setting/:id', element: <GrowerUpdateFieldPage /> },
          ],
        },
        { path: 'exporter',
          children: [
            { element: <Navigate to="/dashboard/exporter" replace />, index: true },
            { path: 'list', element: <ExporterListPage /> },
            { path: 'new', element: <ExporterCreatePage /> },
            { path: 'update/:id', element: <ExporterUpdatePage /> },
          ],
        },
        { path: 'packing',
          children: [
            { element: <Navigate to="/dashboard/packing" replace />, index: true },
            { path: 'list', element: <PackingListPage /> },
            { path: 'new', element: <PackingCreatePage /> },
            { path: 'update/:id', element: <PackingUpdatePage /> },
            { path: 'setting/:id', element: <PackingUpdateFormatPage /> },
            { path: 'size/:id', element: <PackingUpdateSizePage /> },
            { path: 'intake/:id', element: <PackingUpdateIntakeFormatPage /> },

          ],
        },
        { path: 'checkdrive',
          children: [
            { element: <Navigate to="/dashboard/checkdrive" replace />, index: true },
            { path: 'account', element: <GeneralFilePage /> },
          ],
        },
      ],
    },


    // Pages  
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'privacy-policies', element: <PrivacyPolicies /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

