// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  'Desempeño': `Performance`,
  'Cuenta': `Account`,
  'Todos': `All`,
  'Clientes': `Clients`,
  'Reportes': `Reports`,
  'Compañía': `Company`,
  'Exportadores': `Exporters`,
  'Productores': `Growers`,
  'Especies': `Species`,
  'Normas': `Rules`,
  'Todas': `All`,
  'Nueva Norma': `New Rule`,
  'Variedades': `Varieties`,
  'Recepción': 'Reception',
  'Producto Terminado': 'Finished Product',
  'Destino': 'Destination',
  'Visita': 'Visit',
  'all': 'All',
  'En curso': 'In Progress',
  'Terminado': 'Completed',
  'Enviado': 'Shipped',
  'Cliente': 'Client',
  'N° Referencia': 'Reference number',
  'N° Contenedor': 'Container number',
  'Especie': 'Species',
  'Kg entreda': 'Kg input',
  'N° Muestras': 'Number of Boxes',
  'Status': 'Status',
  'Fecha de creación': 'Creation Date',
  'Reporte': 'State',
  'Información General': `General Information`,
  'Configuraciones de la cuenta': `Account Settings`,
  'Nombre de la empresa': `Company Name`,
  'Correo electrónico': `Email`,
  'Usuarios': `Users`,
  'Guardar cambios': `Save changes`,
  'Nombre del cliente': `Client Name`,
  'Estado': `State`,
  'Nuevo cliente': `New Client`,
  'Todos los clientes': `All Clients`,
  'Activo': `Active`,
  'Inactivo': `Inactive`,
  'Activos': `Active`,
  'Inactivos': `Inactive`,
  'Nombre': `Name`,
  'Todas las especies': `All Species`,
  'Nueva especie': `New Species`,
  'Nueva variedad': `New Variety`,
  'Cliente asignado': `Assigned Client`,
  'Nombre especie': `Species Name`,
  'Nombre variedad': `Variety Name`,
  'Todas las variedades': `All Varieties`,
  'Nombre Productor': `Grower Name`,
  'Todos los productores': `All Growers`,
  'Nueva productor': `New Grower`,
  'Nombre Exportador': `Exporter Name`,
  'Todos los exportadores': `All Exporters`,
  'Nuevo exportador': `New Exporter`,
  'Sitios de embalaje': `Packaging sites`,
  'Nombre Packing': `Packing Name`,
  'Todos los Sitios de embalaje': `All Packaging Sites`,
  'Nuevo sitio de embalaje': `New packaging site`,
  'Crear nuevo cliente': `Create new client`,
  'Nuevo Cliente': `New Client`,
  'Activar cliente': `Activate client`,
  'Idioma de reporte': `Reporting language`,
  '¡Cliente actualizado con éxito!': `Client updated successfully!`,
  'Error al enviar datos:': `Error sending data:`,
  'Editar cliente': `Edit client`,
  'Crear nueva especie': `Create new species`,
  'Nueva Especie': `New Species`,
  'Activar especie': `Activate species`,
  'Nombre de la especie': `Species Name`,
  'Editar especie': `Edit species`,
  'Editar Especie': `Edit Species`,
  'Actualizar especie': `Update species`,
  'Crear nueva Variedad': `Create new Variety`,
  'Nueva Variedad': `New Variety`,
  'Activar variedad': `Activate variety`,
  'Variedad': `Variety`,
  'Crear variedad': `Create variety`,
  'Nombre de la variedad': `Variety Name`,
  'Especie/Cliente': `Species/Client`,
  'Editar Variedad': `Edit Variety`,
  'Actualizar variedad': `Update variety`,
  'Nuevo productor': `New Grower`,
  'Crear nuevo productor': `Create new grower`,
  'Crear nuevo Productor': `Create new grower`,
  'Nombre del Productor': `Grower Name`,
  'Editar productor': `Edit grower`,
  'Actualizar productor': `Update grower`,
  'Nombre del productor': `Grower Name`,
  'Activar Productor': `Activate grower`,
  'Crear nuevo Exportador': `Create new exporter`,
  'Nuevo Exportador': `New Exporter`,
  'Activar Exportador': `Activate exporter`,
  'Nombre del Exportador': `Exporter Name`,
  'Crear nuevo exportador': `Create new exporter`,
  'Editar exportador': `Edit exporter`,
  'Actualizar exportador': `Update exporter`,
  'Nombre del exportador': `Exporter Name`,
  'Editar Exportador': `Edit Exporter`,
  'Activar exportador': `Activate exporter`,
  'Nuevo Packing': `New Packing`,
  'Activar sitio de embalaje': `Activate packaging site`,
  'Nombre del Packing': `Packing Name`,
  'Crear nuevo sitio de embalaje': `Create new packaging site`,
  'Editar sitio de embalaje': `Edit packaging site`,
  'Actualizar sitio de embalaje': `Update packaging site`,
  'Nombre del sitio de embalaje': `Packaging site name`,
  'Detalles': `Details`,
  'Descargar': `Download`,
  'Borrar': `Delete`,
  'Editar': `Edit`,
  '¿Estás seguro de que quieres eliminar al cliente?': `Are you sure you want to delete the client?`,
  '¿Estás seguro de que quieres eliminar esta especie?': `Are you sure you want to delete this species?`,
  'Configurar': `Set up`,
  'Nuevo campo': `New field`,
  'Formatos Empaques': `Packaging Formats`,
  'Tamaños': `Fruit sizes`,
  'Formatos de empaque': `Packaging formats`,
  'Crear formato de empaque': `Create packaging format`,
  'Formato de empaque': `Packaging format`,
  'Modificar formato de empaque': `Modify packaging format`,
  'Cancelar': `Cancel`,
  'Guardar': `Save`,
  'Crear': `Create`,
  'Crear tamaño': `Create size`,
  'Crear Tamaño': `Create size`,
  'Configurar Variedad': `Set up Variety`,
  'Defectos': `Defects`,
  'Lecturas': `Readings`,
  'Colores': `Colors`,
  'tamaños': `Sizes`,
  'Tipo de defecto': `Defect type`,
  'Valor Precución': `Precaution value`,
  'Valor Peligro': `Danger value`,
  'Crear Defecto': `Create defect`,
  'Crear defecto': `Create defect`,
  'Defecto Crítico': `Critical defect`,
  'Valor de Peligro': `Danger value`,
  'Valor de Precaución': `Precaution value`,
  'Nuevo defecto': `New defect`,
  'Tipo': `Type`,
  'Editar defecto': `Edit defect`,
  'Unidad de medida': `Unit of measure`,
  'Valor minimo ideal': `Ideal minimum value`,
  'Valor máximo ideal': `Ideal maximum value`,
  'Crear lectura': `Create reading`,
  'Editar lectura': `Edit reading`,
  'Valor minimo permitido': `Minimum allowed value`,
  'Valor maximo permitido': `Maximum allowed value`,
  'Lectura criticas': `Critical readings`,
  'Lectura critica': `Critical reading`,
  'Actualizar': `Update`,
  'Crear color': `Create color`,
  'Editar tamaño': `Edit size`,
  'Editar color': `Edit color`,
  'Nombre del color': `Color name`,
  'Nuevo Campo': `New field`,
  'Modificar Campo': `Modify field`,
  'Crear Campo': `Create field`,
  'Campos': `Fields`,
  '¡Cliente y norma actualizado con éxito!': `Client and rule updated successfully!`,
  'Error actualizando la norma': `Error updating the rule`,
  'Guardar normas': `Save rules`,
  'Formato de admisión': `Intake Formats`,
  'Nuevo formato de admisión': `New intake format`,
  'Modificar formato de admisión': `Modify intake format`,
  'Crear nuevo formato de admisión': `Create new intake format`,
  'Tipo de registrador':`Logger type`,
  'Tipo de pallet':`Pallet type`,
  
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
