import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DataStore } from 'aws-amplify';
import { Company } from '../../models';

export const fetchCompany = createAsyncThunk(
    'company/fetchCompany',
    async (companyID, { rejectWithValue }) => {
        console.log("companyID: ", companyID); 
        try {
            const company = await DataStore.query(Company, companyID);
            console.log("company Model: ", company);  
            if (company === undefined) {
                // Esto va a desencadenar el caso de rechazo.
                return rejectWithValue('Company not found');
            }
            return company;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        id: null,
        name: '',
        email: '',
        photo: '',
        isActive: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompany.fulfilled, (state, action) => {
                state.id = action.payload.id;
                state.name = action.payload.name;
                state.email = action.payload.email;
                state.photo = action.payload.photo;
                state.isActive = action.payload.isActive;
            })
            .addCase(fetchCompany.rejected, (state, action) => {
                // Puedes manejar el error aquí o en el componente donde se dispara fetchCompany.
                console.error('Failed to fetch company', action.error.message);
            });
    },
});

export default companySlice.reducer;
